/* eslint-disable global-require */
export const ProgramCardObject = [
   {
      id: 1,
      programImage: require('../Assets/Images/img03.jpg'),
      // programName: 'Jenny Villanen',
      programTitle: 'Coming Soon!',
   },
   {
      id: 2,
      programImage: require('../Assets/Images/img05.jpg'),
      // programName: 'Sebastian jacot',
      programTitle: 'Coming Soon!',
   },
   {
      id: 3,
      programImage: require('../Assets/Images/img03.jpg'),
      // programName: 'Maija vivolin',
      programTitle: 'Coming Soon!',
   },
   {
      id: 4,
      programImage: require('../Assets/Images/img05.jpg'),
      // programName: 'Jenny Villanen',
      programTitle: 'Coming Soon!',
   },
   {
      id: 5,
      programImage: require('../Assets/Images/img03.jpg'),
      // programName: 'Jenny Villanen',
      programTitle: 'Coming Soon!',
   },

];
