import React from 'react';

function Contribute() {
  return (
    <div className="contribute">
      <h1>Contribute</h1>
      <p>
        Lorem Ipsum is simply dummy text of the printing and typesetting
        industry. Lorem Ipsum has been the industry standard dummy text
        ever since the 1500s, when an unknown printer took a galley of
        type and scrambled it to make a type specimen book.
      </p>
    </div>
  );
}

export default Contribute;
